<script>
import newsletterFormMixin from '@/v-shop/mixins/newsletter-form-mixin'

export default {
	mixins: [newsletterFormMixin()],
	props: {
		popupConfig: Object,
	},
	mounted() {
		this.place = this.popupConfig.name || 'smart-popup'
	},
}
</script>

<template>
	<Validator v-bind="{ validation }">
		<form @submit.prevent="submit" class="w100">
			<div class="d-flex justify-center py-2 px-6" v-if="popupConfig.ctaSelector == 'btn-text-url'">
				<SafeLink :to="popupConfig.ctaUrl" class="w100">
					<Button class="cta w100" style="border-radius: 10px" @click="$emit('ctaClick')">
						<span class="font-1">{{ popupConfig.ctaBtnText }}</span>
					</Button>
				</SafeLink>
			</div>
			<div v-else>
				<v-row v-if="state != 'success'" class="py-2 px-6" dense>
					<v-col cols="12">
						<v-row>
							<v-col cols="12" sm="6">
								<TextField
									v-model="firstname"
									:color="$vars.primarytext"
									:background-color="$vars.primary"
									dense
									label="nombre"
									hide-details
									outlined
								></TextField>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField
									v-model="email"
									:color="$vars.primarytext"
									:background-color="$vars.primary"
									dense
									label="email"
									hide-details
									outlined
								></TextField>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12">
						<Button class="cta mt-3 w100" style="border-radius: 10px" :loading="loading" type="submit">
							>{{ popupConfig.ctaBtnText }}
						</Button>
					</v-col>
				</v-row>
				<div v-else-if="state == 'success' && popupConfig.successMsg" class="mt-3">
					{{ popupConfig.successMsg }}
				</div>
			</div>
		</form>
	</Validator>
</template>

<style scoped lang="scss">
::v-deep
	.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
	> .v-input__control
	> .v-input__slot
	fieldset {
	border-color: var(--warning);
}
.v-input ::v-deep {
	& input {
		color: var(--secondarytext);
	}
}
</style>

